<template>
  <div class="login-root">
    <div id="login_background"></div>
    <v-container fill-height fluid id="login_form">
      <v-row align="center" justify="center">
        <v-card elevation="5" width="85%" max-width="500px" color="rgb(255, 255, 255, 0.8)">
          <v-card-title>登录</v-card-title>
          <v-form ref="form" v-model="valid" lazy-validation class="px-4 pb-4">
            <v-text-field
              v-model="name"
              :counter="10"
              :rules="nameRules"
              label="昵称"
              required
            ></v-text-field>
            <v-btn
              block
              :disabled="!valid"
              color="primary"
              class="my-4"
              @click="login"
            >
              登录
            </v-btn>
          </v-form>
        </v-card>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import lottie from 'lottie-web'
export default {
  name: 'Login',
  data: () => ({
    name: '',
    valid: false,
    anim: null,
    nameRules: [
      v => !!v || '名称必填',
      v => (v && v.length <= 10) || '昵称必须小于10个字符'
    ]
  }),
  created () {
    this.$store.commit('toggleNavigation', false)
  },
  mounted () {
    this.initAnim()
  },
  beforeDestroy () {
    this.anim.destroy()
  },
  methods: {
    login () {
      if (!this.name) { return }
      console.log(this.uuid().split('-').join(''))
      const user = {
        name: this.name,
        id: this.uuid().split('-').join(''),
        isLogin: true
      }
      this.$store.dispatch('loginUser', user)
      // 返回列表
      this.$router.replace('/')
    },
    initAnim () {
      this.anim = lottie.loadAnimation({
        container: document.getElementById('login_background'),
        renderer: 'svg',
        loop: true,
        autoplay: true,
        path: '/lottie/cecil-portfolio-background.json'
      })
    },
    uuid () {
      const tempUrl = URL.createObjectURL(new Blob())
      const uuid = tempUrl.toString() // blob:https://xxx.com/b250d159-e1b6-4a87-9002-885d90033be3
      URL.revokeObjectURL(tempUrl)
      return uuid.substr(uuid.lastIndexOf('/') + 1)
    }
  }
}
</script>

<style scoped>
  #login_background {
    width: 100%;
    position: absolute;
  }
  .login-root {
    width: 100%;
    height: 100%;
  }
  #login_form {
    position: relative;
  }
</style>
